import React, { useState, useEffect } from 'react';
import { Box, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import DataGrid from '../../../components/DataGrid/DataGrid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CButton } from '../../../components/Button/Button';
import { useFetchData } from '../../../components/Firebase/useFirebaseQueries';
import Loader from '../../../components/Loader/Loader';
import Notification from '../../../components/Notification/Notifications';
import { VendorListColumns } from '../../../data/ColumnHeader';

export const VendorList = () => {
    const [vendorOptions, setVendorOptions] = useState([]);
    const [vendorDetails, setVendorDetails] = useState({ vendorId: '' });
    const [selectedSearch, setSelectedSearch] = useState('');
    const [rows, setRows] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState({ severity: "", message: "" });

    const { data: vendors, isLoading: vendorsLoading, error: vendorsError } = useFetchData("vendors");

    useEffect(() => {
        if (vendors) {
            // Populate the vendor options for the Autocomplete
            const transformedOptions = vendors.map((vendor) => ({
                label: vendor.name,
                value: vendor.id,
            }));
            setVendorOptions(transformedOptions);

            // Transform the vendor data for the DataGrid rows
            const transformedRows = vendors.map((vendor) => ({
                id: vendor.id,
                vendorName: vendor.name,
                gstNumber: vendor.gst,
                email: vendor.email,
                address: vendor.address,
                phone: vendor.phone,
                actions: 'Actions', // Placeholder for any actions if needed
            }));
            setRows(transformedRows);
        }
    }, [vendors]);

    const handleVendorChange = (event, newValue) => {
        // If newValue is null (i.e., user clicked the cross button), reset the vendor selection
        if (newValue === null) {
            setVendorDetails({ vendorId: '' });
        } else {
            setVendorDetails({ vendorId: newValue ? newValue.value : '' });
        }
    };

    const handleSearchChange = (event) => {
        setSelectedSearch(event.target.value);
    };

    useEffect(() => {
        if (vendors) {
            setShowLoader(true);

            const filteredVendorData = vendors
                .filter(item => {
                    const isVendorMatch = !vendorDetails.vendorId || item.id === vendorDetails.vendorId;
                    const isSearchMatch = !selectedSearch || item.name.toLowerCase().includes(selectedSearch.toLowerCase());
                    return isVendorMatch && isSearchMatch;
                })
                .map(item => ({
                    id: item.id,
                    vendorName: item.name,
                    gstNumber: item.gst,
                    email: item.email,
                    address: item.address,
                    phone: item.phone,
                    actions: 'Actions',
                }));

            setRows(filteredVendorData);
            setShowLoader(false);
        }
    }, [vendorDetails.vendorId, selectedSearch, vendors]);

    useEffect(() => {
        if (vendorsError) {
            setNotificationMsg({
                severity: "error",
                message: "Error loading data. Please try again.",
            });
            setShowNotification(true);
        }
    }, [vendorsError]);

    if (vendorsLoading) {
        return <Loader open={true} />;
    }

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Autocomplete
                        size="small"
                        disablePortal
                        id="vendor-select"
                        options={vendorOptions}
                        sx={{ width: 200, backgroundColor: "#EAECF0FF", marginRight: 2 }}
                        onChange={handleVendorChange}
                        value={vendorOptions.find(option => option.value === vendorDetails.vendorId) || null} // Reset selection
                        renderInput={(params) => <TextField {...params} label="Select Vendor" />}
                    />
                </Box>
            </Box>

            <Box sx={{ padding: 1 }}>
                <DataGrid
                    columns={VendorListColumns}
                    rows={rows}
                    showActions={false} // Enable action icons (edit/delete) in the DataGrid
                />
            </Box>

            {showNotification && (
                <Notification
                    open={showNotification}
                    onClose={() => setShowNotification(false)}
                    severity={notificationMsg.severity}
                    message={notificationMsg.message}
                />
            )}
        </Box>
    );
};
