

import React, { useState, useEffect } from 'react';
import { Box, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CButton } from '../../../components/Button/Button';
// import { OrderTable } from './OrderTable';
import { useFetchData } from '../../../components/Firebase/useFirebaseQueries';
import Loader from '../../../components/Loader/Loader';
import Notification from '../../../components/Notification/Notifications';

export const OrderList = () => {
    const [vendorOptions, setVendorOptions] = useState([]);
    const [vendorDetails, setVendorDetails] = useState({ vendorId: '', date: '' });
    const [rows, setRows] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState({ severity: "", message: "" });

    const { data: vendors, isLoading: vendorsLoading, error: vendorsError } = useFetchData("vendors");
    const { data: purchaseOrderData, isLoading: quotationDataLoading, error: quotationError } = useFetchData("purchageOrder");
    // console.log(purchaseOrderData, 'podata');
    

    useEffect(() => {
        if (vendors) {
            const transformedOptions = vendors.map((vendor) => ({
                label: vendor.name,
                value: vendor.id,
            }));
            setVendorOptions(transformedOptions);
        }
    }, [vendors]);

    const handleVendorChange = (event, newValue) => {
        setVendorDetails((prev) => ({ ...prev, vendorId: newValue ? newValue.value : '' }));
    };

    const handleDateChange = (event) => {
        setVendorDetails((prev) => ({ ...prev, date: event.target.value }));
    };

    useEffect(() => {
        if (vendorDetails.vendorId && purchaseOrderData) {
            setShowLoader(true);
            const filteredQuotations = purchaseOrderData.filter(quotation => quotation.vendorId === vendorDetails.vendorId);
            const filteredOrderWithQuotationId = filteredQuotations.reduce((acc, quotation) => {
                const quotationId = quotation.quotationId;
                if (!acc[quotationId]) {
                    acc[quotationId] = [];
                }
                acc[quotationId].push(quotation);
                return acc;
            }, {});
            setRows(Object.values(filteredOrderWithQuotationId).flat());
            setShowLoader(false);
        }
    }, [vendorDetails.vendorId, purchaseOrderData]);

    useEffect(() => {
        if (vendorsError || quotationError) {
            setNotificationMsg({
                severity: "error",
                message: "Error loading data. Please try again.",
            });
            setShowNotification(true);
        }
    }, [vendorsError, quotationError]);

    if (vendorsLoading || quotationDataLoading) {
        return <Loader open={true} />;
    }

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Autocomplete
                        size="small"
                        disablePortal
                        id="vendor-select"
                        options={vendorOptions}
                        sx={{ width: 200, backgroundColor: "#EAECF0FF", marginRight: 2 }}
                        onChange={handleVendorChange}
                        renderInput={(params) => <TextField {...params} label="Select Vendor" />}
                    />
                    <TextField
                        id="date"
                        label="Select Date"
                        type="date"
                        size="small"
                        sx={{ width: 200, backgroundColor: "#EAECF0FF" }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleDateChange}
                    />
                </Box>
                <CButton
                    size= "small"
                    style={{ backgroundColor: "#FFB91DFF", color: "white", marginRight: "10px" }}
                    type="button"
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    text="Export as Excel"
                />
            </Box>
            {/* <Box sx={{ marginTop: 3 }}>
                <OrderTable
                    data={rows}
                    vendorData={vendorOptions.find(v => v.value === vendorDetails.vendorId)}
                />
            </Box> */}

            {showNotification && (
                <Notification
                    open={showNotification}
                    onClose={() => setShowNotification(false)}
                    severity={notificationMsg.severity}
                    message={notificationMsg.message}
                />
            )}
        </Box>
    );
};
