// import { initializeApp } from 'firebase/app';
// import { getFirestore } from 'firebase/firestore';

// const firebaseConfig = {
//   apiKey: "AIzaSyCNp3Qf0bA-je0fqwESO2uwQhQR6NWBQuA",
//   authDomain: "cliniosqa-7f2c0.firebaseapp.com",
//   projectId: "cliniosqa-7f2c0",
//   storageBucket: "cliniosqa-7f2c0.appspot.com",
//   messagingSenderId: "498980221279",
//   appId: "1:498980221279:web:193be9b8e5d589f748c6ea",
// };

// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);

// export { db };

let firebaseConfig;

if (process.env.REACT_APP_ENV === 'production') {
  firebaseConfig = require('./firebase/firebase.prod').default;
} else if (process.env.REACT_APP_ENV === 'qa') {
  firebaseConfig = require('./firebase/firebase.qa').default;
} else {
  firebaseConfig = require('./firebase/firebase.dev').default;
}

export const db = firebaseConfig.db;


