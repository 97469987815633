import React, { lazy, Suspense } from 'react';
import { Routes, Route } from "react-router-dom";
// import Login from "./scenes/login/Login";
// import Dashboard from "./scenes/dashboard";
// import ProtectedRoute from "./components/protectedRoutes/ProtectedRoute";
// import PurchaseRequisition from "./scenes/purchaseRequisition/PurchaseRequisition";
// import Quotation from "./scenes/purchaseRequisition/Quotation";
// import MasterList from "./scenes/purchaseRequisition/MasterList";
// import PurchaseOrder from "./scenes/purchaseRequisition/PurchaseOrder";
// import AddInvoice from "./scenes/Invoice/addInvoice";
// import Billing from "./scenes/sales/Billing";
// import AllBills from "./scenes/sales/AllBills";
// import PharmacyInventory from "./scenes/Invoice/PharmacyInventory/PharmacyInventory";
import RequisitionList from "./scenes/reports/RequisitionList/RequisitionList";
import { OrderList } from "./scenes/reports/OrderList/OrderList";
import { ReturnList } from "./scenes/reports/ReturnList/ReturnList";
import { PaymentsList } from "./scenes/reports/PaymentList/PaymentList";
import { InvoiceList } from "./scenes/reports/InvoiceList/InvoiceList";
import { VendorList } from "./scenes/reports/VendorList/VendorList";
import Loader from './components/Loader/Loader';
import { InventoryReport } from './scenes/reports/InventoryReport/InventoryReport';
import { SalesReports } from './scenes/reports/SalesReport/SalesReport';

const Login = lazy(() => import("./scenes/login/Login"));
const Dashboard = lazy(() => import("./scenes/dashboard"));
const ProtectedRoute = lazy(() => import("./components/protectedRoutes/ProtectedRoute"));
const PurchaseRequisition = lazy(() => import("./scenes/purchaseRequisition/PurchaseRequisition"));
const Quotation = lazy(() => import("./scenes/purchaseRequisition/Quotation"));
const MasterList = lazy(() => import("./scenes/purchaseRequisition/MasterList"));
const PurchaseOrder = lazy(() => import("./scenes/purchaseRequisition/PurchaseOrder"));
const AddInvoice = lazy(() => import("./scenes/Invoice/addInvoice"));
const Billing = lazy(() => import("./scenes/sales/Billing"));
const AllBills = lazy(() => import("./scenes/sales/AllBills"));
const PharmacyInventory = lazy(() => import("./scenes/Invoice/PharmacyInventory/PharmacyInventory"));
const Payments = lazy(() => import ("./scenes/purchaseRequisition/PaymentHistory"));

function App() {
  return (
    <Suspense fallback={<Loader open={true}/>}>
    <Routes>
      <Route path="/:id" element={<Login />} />
      <Route
        path="/purchase"
        element={<ProtectedRoute element={<Dashboard />} />}
      >
        <Route index element={<PurchaseRequisition />} />
        <Route path="purchaseRequisition" element={<PurchaseRequisition />} />
        <Route path="quotation" element={<Quotation />} />
        <Route path="bestPricingOrder" element={<MasterList />} />
        <Route path="purchaseorder" element={<PurchaseOrder />} />
        <Route path="addPayment" element={<Payments />} />
      </Route>
      <Route
        path="/inventory"
        element={<ProtectedRoute element={<Dashboard />} />}
      >
        <Route index element={<AddInvoice />} />
        <Route path="addInvoice" element={<AddInvoice />} />
        <Route path="PharmacyInventory" element={<PharmacyInventory />} />
      </Route>
      <Route path="/sales" element={<ProtectedRoute element={<Dashboard />} />}>
        <Route index element={<Billing />} />
        <Route path="billing" element={<Billing />} />
        <Route path="allBills" element={<AllBills />} />
      </Route>
      <Route
        path="/reports"
        element={<ProtectedRoute element={<Dashboard />} />}
      >
        <Route path="RequisitionList" element={<RequisitionList />} />
        <Route path="PurchaseOrderList" element={<OrderList />} />
        <Route path="ReturnList" element={<ReturnList/>} />
        <Route path="PaymentsList" element={<PaymentsList/>} />
        <Route path="InvoiceList" element={<InvoiceList/>} />
        <Route path="VendorList" element={<VendorList/>} />
      </Route>
      <Route
        path="/reports"
        element={<ProtectedRoute element={<Dashboard />} />}
      >
        {/* <Route path="InventoryReport" element={<InventoryReport />} /> */}
        {/* <Route path="SalesReport" element={<SalesReports />} /> */}
        </Route>
    </Routes>
  </Suspense>
  );
}

export default App;