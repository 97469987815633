import React from "react";
import { Snackbar, Alert } from "@mui/material";

export default function Notification({ message, action = false, onClose, severity }) {
  return (
    <Snackbar
      open={action}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Centered on the screen
      autoHideDuration={3000} // Optional: Duration to auto-hide
    >
      <Alert 
        onClose={onClose} 
        severity={severity} 
        sx={{ 
          width: '100%', 
          maxWidth: { xs: '70%', sm: '400px' }, // Responsive size
          mx: 'auto', // Centers the alert horizontally within Snackbar
          fontSize: { xs: '14px', sm: '15px' } // Adjust font size for responsiveness
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
