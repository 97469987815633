import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import { generatePdf } from "../../../components/Pdf/PdfFile";
import { useGetDocsByQuery } from "../../../components/Firebase/useFirebaseQueries";
import useLocalStorage from "../../../Hooks/UseLocalstorage";
import { formatDate, updateTableHeight } from "../../../utils/Utils"; // Import the utility

const pdfColumns = [
  { Header: "Pharmacological Name", accessor: "pharmacologicalName" },
  { Header: "Brand Name", accessor: "brandName" },
  { Header: "Dose", accessor: "dose" },
  { Header: "Form", accessor: "form" },
  { Header: "Quantity / Strips", accessor: "quantity" },
];

export const CollapsibleTable = ({ data, vendorData }) => {
  const [pharmacyId] = useLocalStorage("pharmacyId");
  const [tableHeight, setTableHeight] = useState("auto");

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const { data: pharmacy } = useGetDocsByQuery(
      "pharmacyList",
      [{ field: "pharmacyId", operator: "==", value: pharmacyId }],
      true
    );
    const handleDownloadPdf = () => {
      generatePdf({
        columns: pdfColumns,
        vendorDetails: vendorData,
        data: row?.medicines,
        pdfTitle: "PURCHASE REQUISITION",
        pharmacy: pharmacy,
      });
    };

    return (
      <Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row?.vendorName}
          </TableCell>
          <TableCell align="right">{formatDate(row?.date)}</TableCell> {/* Use formatDate here */}
          <TableCell align="right">{row?.status}</TableCell>
          <TableCell align="right">{row?.medicines?.length}</TableCell>
          <TableCell align="right">
            <Button variant="contained" onClick={handleDownloadPdf}>
              Download PDF
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Medicines List
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead sx={{backgroundColor: "#EAECF0FF"}}>
                    <TableRow>
                      <TableCell>Pharmacological Name</TableCell>
                      <TableCell>Brand Name</TableCell>
                      <TableCell align="right">Dose</TableCell>
                      <TableCell align="right">Form</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.medicines?.map((medicine) => (
                      <TableRow key={medicine?.pharmacologicalName}>
                        <TableCell component="th" scope="row">
                          {medicine?.pharmacologicalName}
                        </TableCell>
                        <TableCell>{medicine?.brandName}</TableCell>
                        <TableCell align="right">{medicine?.dose}</TableCell>
                        <TableCell align="right">{medicine?.form}</TableCell>
                        <TableCell align="right">
                          {medicine?.quantity}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  useEffect(() => {
    const updateHeight = () => {
      const windowHeight = window.innerHeight;
      setTableHeight(`${windowHeight * 0.6}px`);
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: tableHeight,
        overflowY: "auto", // Enable internal scrolling
        width: "100%", // Make the table container full width
      }}
    >
      <Table stickyHeader aria-label="collapsible table" sx={{ width: "100%" }}>
        <TableHead sx={{ backgroundColor: "#EAECF0FF" }}>
          <TableRow>
            <TableCell />
            <TableCell>Vendor Name</TableCell>
            <TableCell align="right">Created Date</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">No of Medicines</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <Row key={row?.requisitionId} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// CSS Styles (optional)
const styles = `
.table-container {
  width: 100%;
}

.table-row {
  cursor: pointer;
}

.expanded-view {
  padding: 10px;
  background-color: #f9f9f9;
}
`;

export default CollapsibleTable;