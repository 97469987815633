import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDEKsSVwGnR9OPNh-qdm6kzK8hwuZLqNR4",
  authDomain: "cliniosglobal-d0866.firebaseapp.com",
  projectId: "cliniosglobal-d0866",
  storageBucket: "cliniosglobal-d0866.firebasestorage.app",
  messagingSenderId: "846198919507",
  appId: "1:846198919507:web:a4fe25bf26708ee17091a6",
  measurementId: "G-SL259L6F5H"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const firebaseConfigObject = { db };

export default firebaseConfigObject;
