import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import { generatePdf } from "../../../components/Pdf/PdfFile";
import { useGetDocsByQuery } from "../../../components/Firebase/useFirebaseQueries";
import useLocalStorage from "../../../Hooks/UseLocalstorage";
import { formatDate, updateTableHeight } from "../../../utils/Utils"; // Import formatDate and updateTableHeight
import { addRupeeSymbol } from "../../../utils/Utils";

// Define the columns for PDF generation
const pdfColumns = [
  { Header: "Medicine Name", accessor: "brandName" },
  { Header: "Batch No", accessor: "batchNo" },
  { Header: "HSN code", accessor: "hsnCode" },
  { Header: "Expiry", accessor: "expiry" },
  { Header: "Units per Strips", accessor: "quantity" },
  { Header: "No. of strips", accessor: "noOfStrips" },
  { Header: "Free Strips", accessor: "freeStrips" },
  { Header: "MRP / Strip", accessor: "mrpPerStrip" },
  { Header: "Price / Strip", accessor: "pricePerStrip" },
  { Header: "Discount %", accessor: "discount" },
  { Header: "GST", accessor: "gst" },
  { Header: "Net Price", accessor: "netPrice" },
];

export const InvoiceTable = ({ data, vendorData }) => {
  const [pharmacyId] = useLocalStorage("pharmacyId");
  const [tableHeight, setTableHeight] = useState("auto"); // State to manage table height

  // Dynamically set table height on component mount and window resize
  useEffect(() => {
    const updateHeight = () => {
      const windowHeight = window.innerHeight; // Get the current window height
      setTableHeight(`${windowHeight * 0.6}px`); // Set the height to a percentage of the viewport height
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const Row = (props) => {
    const { row } = props;
    console.log(row, 'rows');
    
    const [open, setOpen] = useState(false);
    const { data: pharmacy } = useGetDocsByQuery(
      "pharmacyList",
      [{ field: "pharmacyId", operator: "==", value: pharmacyId }],
      true
    );
    
    const handleDownloadPdf = () => {
      generatePdf({
        columns: pdfColumns,
        vendorDetails: vendorData,
        data: row?.medicines,
        pdfTitle: "INVOICE DETAILS",
        pharmacy: pharmacy,
      });
    };

    return (
      <Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }} className="table-row">
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {formatDate(row?.invoiceDate)}
          </TableCell>
          <TableCell align="left">{row?.invoiceNumber}</TableCell>
          <TableCell align="left">{row?.vendorName}</TableCell>
          <TableCell align="left">{addRupeeSymbol(row?.totalAmount)}</TableCell>
          <TableCell align="left">{addRupeeSymbol(row?.balanceAmount)}</TableCell>
          <TableCell align="left">{row?.paymentStatus}</TableCell>
          <TableCell align="left">
            <Button 
              variant="contained" 
              color="primary" 
              className="download-button"
              onClick={handleDownloadPdf}
            >
              Download PDF
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 2 }} className="expanded-view">
                <Typography variant="h6" gutterBottom component="div">
                  Medicines List
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead sx={{ backgroundColor: "#EAECF0FF" }}>
                    <TableRow>
                      <TableCell>Medicine Name</TableCell>
                      <TableCell>Batch No</TableCell>
                      <TableCell align="left">HSN Code</TableCell>
                      <TableCell align="left">Expiry</TableCell>
                      <TableCell align="left">Units / Strip</TableCell>
                      <TableCell align="left">No of Strips</TableCell>
                      <TableCell align="left">Free Strips</TableCell>
                      <TableCell align="left">MRP / Strip</TableCell>
                      <TableCell align="left">Price / Strip</TableCell>
                      <TableCell align="left">Discount</TableCell>
                      <TableCell align="left">GST%</TableCell>
                      <TableCell align="left">Net Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.medicines?.map((medicine) => (
                      <TableRow key={medicine?.brandName}>
                        <TableCell>{medicine?.brandName}</TableCell>
                        <TableCell align="left">{medicine?.batchNo}</TableCell>
                        <TableCell align="left">{medicine?.hsnCode}</TableCell>
                        <TableCell align="left">{formatDate(medicine?.expiry)}</TableCell>
                        <TableCell align="left">{medicine?.unitsOrStrips}</TableCell>
                        <TableCell align="left">{medicine?.noOfStrips}</TableCell>
                        <TableCell align="left">{medicine?.freeStrips}</TableCell>
                        <TableCell align="left">{addRupeeSymbol(medicine?.mrpPerStrip)}</TableCell>
                        <TableCell align="left">{addRupeeSymbol(medicine?.pricePerStrip)}</TableCell>
                        <TableCell align="left">{medicine?.discount}</TableCell>
                        <TableCell align="left">{medicine?.gst}</TableCell>
                        <TableCell align="left">{addRupeeSymbol(medicine?.netPrice)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: tableHeight, // Dynamic height based on viewport
        overflowY: "auto", // Enable internal scrolling
        width: "100%", // Make the table container full width
      }}
    >
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Invoice Date</TableCell>
            <TableCell align="left">Invoice No</TableCell>
            <TableCell align="left">Vendor Name</TableCell>
            <TableCell align="left">Invoice Amount</TableCell>
            <TableCell align="left">Balance Amount</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <Row key={row?.invoiceId} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// CSS
const styles = `
.invoice-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: separate;
  border-spacing: 0 15px;
}

.table-row:hover {
  background-color: #f5f5f5;
}

.expanded-view {
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 8px;
}

.download-button {
  margin-left: auto;
  margin-right: auto;
  background-color: #1976d2;
  color: #fff;
}

.medicine-row {
  border-bottom: 1px solid #e0e0e0;
}
`;

export default InvoiceTable;