import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  fetchData,
  createData,
  updateData,
  bulkUpdateData,
  deleteData,
  getDocsByQuery,
  bulkCreateData,
  fetchDocsByIds,
} from "./firebaseOperations";

// Fetch data hook
export const useFetchData = (collectionName) => {
  return useQuery({
    queryKey: [collectionName],
    queryFn: async () => {
      try {
        const data = await fetchData(collectionName);
        // console.log("Fetched data in useFetchData:", data); // Ensure data is logged correctly here
        return data;
      } catch (error) {
        console.error("Error fetching data in useFetchData: ", error);
        throw new Error(error.message);
      }
    },
    onError: (error) => {
      console.error("Error in useQuery: ", error);
    },
    refetchOnWindowFocus: false, // Prevents refetch on window focus
    staleTime: 5 * 60 * 1000, // Data is considered fresh for 5 minutes
    cacheTime: 10 * 60 * 1000, // Unused data stays in cache for 10 minutes
  });
};

//get docs by conditions

export const useGetDocsByQuery = (collectionName, conditions, enabled) => {
  return useQuery({
    queryKey: [collectionName, conditions],
    queryFn: () => getDocsByQuery(collectionName, conditions),
    enabled: enabled,
    onError: (error) => {
      console.error("Error in useQuery:", error);
    },
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

// get docs size

export const useGetDocsSizeByQuery = (collectionName, conditions, enabled) => {
  return useQuery({
    queryKey: [collectionName, conditions],
    queryFn: () => getDocsByQuery(collectionName, conditions),
    enabled: enabled,
    onError: (error) => {
      console.error("Error in useQuery:", error);
    },
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

// Create data hook
export const useCreateData = (collectionName) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newData) => createData(collectionName, newData),
    onSuccess: () => {
      queryClient.invalidateQueries([collectionName]);
    },
    onError: (error) => {
      console.error("Error creating data: ", error);
    },
  });
};

// Update data hook
export const useUpdateData = (collectionName) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      try {
        const updatedData = await updateData(collectionName, data);
        console.log("Updated data in useUpdateData:", updatedData);
        return updatedData;
      } catch (error) {
        console.error("Error updating data in useUpdateData:", error);
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([collectionName]);
    },
    onError: (error) => {
      console.error("Error in useUpdateData:", error);
    },
  });
};

// Bulk update data hook
export const useBulkUpdateData = (collectionName) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (updates) => {
      try {
        console.log(collectionName, updates, "useF");
        const updatedData = await bulkUpdateData(collectionName, updates);
        return updatedData;
      } catch (error) {
        console.error("Error bulk updating data in useBulkCreateData:", error);
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([collectionName]);
    },
    onError: (error) => {
      console.error("Error in useBulkCreateData:", error);
    },
  });
};

// Bulk create data hook
export const useBulkCreateData = (collectionName) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (updates) => {
      try {
        const updatedData = await bulkCreateData(collectionName, updates);
        return updatedData;
      } catch (error) {
        console.error("Error bulk updating data in useBulkCreateData:", error);
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([collectionName]);
    },
    onError: (error) => {
      console.error("Error in useBulkCreateData:", error);
    },
  });
};

// Delete data hook
export const useDeleteData = (collectionName) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id) => {
      try {
        const data = await deleteData(collectionName, id);
        console.log("Deleted data in useDeleteData:", data);
        return data;
      } catch (error) {
        console.error("Error deleting data in useDeleteData:", error);
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([collectionName]);
    },
    onError: (error) => {
      console.error("Error in useDeleteData:", error);
    },
  });
};

export const useFetchDocsByIds = (collectionName, docIds, enabled) => {
  return useQuery({
    queryKey: [collectionName, docIds], // Unique query key based on collection and IDs
    queryFn: async () => {
      try {
        const data = await fetchDocsByIds(collectionName, docIds);
        return data;
      } catch (error) {
        console.error("Error fetching documents in useFetchDocsByIds: ", error);
        throw new Error(error.message);
      }
    },
    enabled: enabled,
    onError: (error) => {
      console.error("Error in useQuery: ", error);
    },
    refetchOnWindowFocus: false, // Prevents refetch on window focus
    staleTime: 5 * 60 * 1000, // Data is considered fresh for 5 minutes
    cacheTime: 10 * 60 * 1000, // Unused data stays in cache for 10 minutes
  });
};
