import React, { useState, useEffect } from 'react';
import { Box, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import DataGrid from '../../../components/DataGrid/DataGrid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CButton } from '../../../components/Button/Button';
import { useFetchData } from '../../../components/Firebase/useFirebaseQueries';
import Loader from '../../../components/Loader/Loader';
import Notification from '../../../components/Notification/Notifications';
import { PaymentListColumns } from '../../../data/ColumnHeader';

export const PaymentsList = () => {
    const [vendorOptions, setVendorOptions] = useState([]);
    const [vendorDetails, setVendorDetails] = useState({ vendorId: '' });
    const [selectedDate, setSelectedDate] = useState('');
    const [rows, setRows] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState({ severity: "", message: "" });

    const { data: vendors, isLoading: vendorsLoading, error: vendorsError } = useFetchData("vendors");
    const { data: paymentData, isLoading: paymentDataLoading, error: paymentDataError } = useFetchData("paymentsList");

    useEffect(() => {
        if (vendors) {
            const transformedOptions = vendors.map((vendor) => ({
                label: vendor.name,
                value: vendor.id,
            }));
            setVendorOptions(transformedOptions);
        }
    }, [vendors]);

    const handleVendorChange = (event, newValue) => {
        setVendorDetails({ vendorId: newValue ? newValue.value : '' });
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    useEffect(() => {
        if (vendorDetails.vendorId && paymentData) {
            setShowLoader(true);

            const filteredPaymentData = paymentData
                .filter(item =>
                    item.vendorId === vendorDetails.vendorId &&
                    (!selectedDate || item.invoiceDueDate === selectedDate)
                )
                .map(item => ({
                    id: item.id,
                    vendorName: item.vendorName,
                    invoiceNo: item.invoiceNo,
                    invoiceAmount: item.invoiceAmount,
                    paidAmount: item.paidAmount,
                    dueAmount: item.dueAmount,
                    invoiceDueDate: item.invoiceDueDate,
                    view: 'View', // Placeholder for view action or link
                }));

            setRows(filteredPaymentData);
            setShowLoader(false);
        }
    }, [vendorDetails.vendorId, selectedDate, paymentData]);

    useEffect(() => {
        if (vendorsError || paymentDataError) {
            setNotificationMsg({
                severity: "error",
                message: "Error loading data. Please try again.",
            });
            setShowNotification(true);
        }
    }, [vendorsError, paymentDataError]);

    if (vendorsLoading || paymentDataLoading) {
        return <Loader open={true} />;
    }

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Autocomplete
                        size="small"
                        disablePortal
                        id="vendor-select"
                        options={vendorOptions}
                        sx={{ width: 200, backgroundColor: "#EAECF0FF", marginRight: 2 }}
                        onChange={handleVendorChange}
                        renderInput={(params) => <TextField {...params} label="Select Vendor" />}
                    />

                    <TextField
                        label="Select Date"
                        type="date"
                        size="small"
                        value={selectedDate}
                        onChange={handleDateChange}
                        sx={{ width: 200, backgroundColor: "#EAECF0FF", marginRight: 2 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <Autocomplete
                        size="small"
                        disablePortal
                        id="select-options"
                        options={[]} // No predefined options
                        sx={{ width: 200, backgroundColor: "#EAECF0FF", marginRight: 2 }}
                        renderInput={(params) => <TextField {...params} label="Select Options" />}
                    />
                </Box>

                <CButton
                    size="small"
                    style={{ backgroundColor: "#FFB91DFF", color: "white" }}
                    type="button"
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    text="Export as Excel"
                />
            </Box>

            <Box sx={{ padding: 1 }}>
                <DataGrid
                    columns={PaymentListColumns}
                    rows={rows}
                    showActions={false}
                    // showCheckboxes={true}
                />
            </Box>

            {showNotification && (
                <Notification
                    open={showNotification}
                    onClose={() => setShowNotification(false)}
                    severity={notificationMsg.severity}
                    message={notificationMsg.message}
                />
            )}
        </Box>
    );
};
