import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export const generatePdf = ({
  data,
  vendorDetails,
  pdfTitle,
  columns,
  pharmacy,
}) => {
  console.log("data =>", data, "columns", columns);
  const {
    address: vendorAddress = "",
    email = "",
    gst = "",
    name: vendorName = "",
    phone: vendorPhone = "",
  } = vendorDetails || {};

  const [{ address = "", name = "", phone = "" } = {}] = pharmacy || [];

  const time = new Date().getTime();

  try {
    const doc = new jsPDF();

    const headers = columns.map((col) => col.Header);
    const accessors = columns.map((col) => col.accessor || col.field);

    const rows = data.map((item) =>
      accessors.map((accessor) => {
        let value = item[accessor];

        // Check if accessor is 'quantity' and leave as-is
        if (accessor === "quantity") {
          return value;
        }

        // Handle date validation: only process as a date if the value is a string that looks like a date
        if (typeof value === "string" && value.match(/^\d{4}-\d{2}-\d{2}/)) {
          return moment(value).format("DD/MM/YYYY");
        }

        return value; // Return as-is if not a date
      })
    );

    // PDF title and date
    doc.setFontSize(16);
    doc.text(pdfTitle, doc.internal.pageSize.getWidth() / 2, 10, {
      align: "center",
    });
    doc.setFontSize(12);
    doc.text(
      `Date: ${moment(new Date()).format("DD/MM/YYYY")}`,
      doc.internal.pageSize.getWidth() - 10,
      10,
      { align: "right" }
    );

    // Vendor details (To Address)
    doc.text("To,", 10, 20);
    doc.text(vendorName, 10, 25);
    doc.text(vendorAddress, 10, 30);
    doc.text(`Mobile: ${vendorPhone}`, 10, 35);

    // Pharmacy details (From Address)
    const fromAddressX = doc.internal.pageSize.getWidth() - 10;
    doc.text("From,", fromAddressX, 20, { align: "right" });
    doc.text(`${name},`, fromAddressX, 25, { align: "right" });
    doc.text(address || "", fromAddressX, 30, {
      align: "right",
    });
    doc.text(phone, fromAddressX, 35, { align: "right" });

    // Table of data
    doc.autoTable({
      head: [headers],
      body: rows,
      startY: 40,
      startX: 20,
    });

    // Footer with page numbers
    const totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.text(
        `Text at the end of Page ${i}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    }

    // Save the PDF
    doc.save(`${pdfTitle}_${time}_${vendorName}.pdf`);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};
