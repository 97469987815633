import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";

export const CButton = ({
  text,
  variant,
  buttonHandler = () => {},
  type,
  style = {},
  startIcon,
  disabled,
  size,
}) => {
  return (
    <Button
        size={size}
        style={style}
        type={type}
        onClick={buttonHandler}
        variant={variant}
        startIcon={startIcon}
        disabled={disabled}
      >
        {text}
      </Button>
  );
};

/*

Variants ==> contained, text, outlined
*/
