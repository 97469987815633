import React, { useState, useEffect } from 'react';
import { Box, Button, TextField } from "@mui/material";
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader/Loader';
import Notification from '../../../components/Notification/Notifications';
import { CollapsibleTable } from './CollapsibleTable';
import Autocomplete from '@mui/material/Autocomplete';
import { useFetchData } from '../../../components/Firebase/useFirebaseQueries';

const RequisitionList = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [rows, setRows] = useState([]);
    const [options, setOptions] = useState([]);
    const [vendorDetails, setVendorDetails] = useState({});
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState({
        severity: "",
        message: "",
    });
    const [vendorSelected, setVendorSelected] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');

    const { data: vendors } = useFetchData("vendors");
    const { data: requisitionData } = useFetchData("purchaseRequisition");

    useEffect(() => {
        if (vendors) {
            const transformedObject = vendors.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setOptions(transformedObject);
        }
    }, [vendors]);

    useEffect(() => {
        setShowLoader(true);

        let filteredData = requisitionData || [];

        if (vendorSelected && vendorDetails.vendorId) {
            filteredData = filteredData.filter(item => item.vendorId === vendorDetails.vendorId);
        }

        if (selectedDate) {
            filteredData = filteredData.filter(item => new Date(item.date).toDateString() === new Date(selectedDate).toDateString());
        }

        setRows(filteredData);
        setShowLoader(false);
    }, [vendorDetails, selectedDate, requisitionData]);

    const vendorHandler = (event, value) => {
        if (value) {
            const { label, value: vendorId } = value;
            setVendorDetails({ vendorId, vendorName: label });
            setVendorSelected(true);
        } else {
            setVendorDetails({});
            setVendorSelected(false);
            setRows([]);
        }
    };

    const handleInputChange = (field, value) => {
        if (field === "selectedDate") {
            setSelectedDate(value);
        }
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Autocomplete
                    onChange={vendorHandler}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={options}
                    sx={{ width: 200, backgroundColor: "#EAECF0FF" }}
                    renderInput={(params) => <TextField {...params} label="Select Vendor" />}
                />
                <TextField
                    size="small"
                    id="select-date"
                    label="Select Date"
                    type="date"
                    value={selectedDate}
                    onChange={(e) => handleInputChange("selectedDate", e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{ marginLeft: 2, width: 200 }}
                />
                <Box sx={{ flexGrow: 1 }} />
                <Link to="/purchase/purchaseRequisition">
                    <Button
                        sx={{
                            backgroundColor: 'rgb(255, 185, 29)',
                            '&:hover': {
                                backgroundColor: 'rgb(255, 185, 29)',
                            },
                        }}
                        variant="contained"
                    >
                        Create New Requisition
                    </Button>
                </Link>
            </Box>
            <Box sx={{ marginTop: 8 }}>
                {rows.length > 0 && (
                    <CollapsibleTable data={rows} vendorData={vendorDetails} />
                )}
            </Box>
            <Loader open={showLoader} />
            <Notification
                open={showNotification}
                severity={notificationMsg.severity}
                message={notificationMsg.message}
            />
        </Box>
    );
};

export default RequisitionList;
