import React, { useState, useEffect } from 'react';
import { Box, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CButton } from '../../../components/Button/Button';
import { useFetchData } from '../../../components/Firebase/useFirebaseQueries';
import Loader from '../../../components/Loader/Loader';
import Notification from '../../../components/Notification/Notifications';
import { InvoiceTable } from './InvoiceTable';

export const InvoiceList = () => {
    const [vendorOptions, setVendorOptions] = useState([]);
    const [vendorDetails, setVendorDetails] = useState({ vendorId: '' });
    const [selectedDate, setSelectedDate] = useState('');
    const [searchInput, setSearchInput] = useState(''); // State for search input
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState({ severity: "", message: "" });

    // Fetch vendors and invoice data
    const { data: vendors, isLoading: vendorsLoading, error: vendorsError } = useFetchData("vendors");
    const { data: invoiceData, isLoading: invoiceDataLoading, error: invoiceDataError } = useFetchData("invoice");
    console.log(invoiceData);

    // Set vendor options when vendors data changes
    useEffect(() => {
        if (vendors) {
            const transformedOptions = vendors.map((vendor) => ({
                label: vendor.name,
                value: vendor.id,
            }));
            setVendorOptions(transformedOptions);
        }
    }, [vendors]);

    // Handle vendor selection change
    const handleVendorChange = (event, newValue) => {
        setVendorDetails({ vendorId: newValue ? newValue.value : '' });
    };

    // Handle date change
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    // Handle search input change
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    // Filter and set invoice data based on selected vendor, date, and search input
    useEffect(() => {
        if (invoiceData) {
            setShowLoader(true);
    
            const filteredData = invoiceData.filter(item =>
                (!vendorDetails.vendorId || item.vendorId === vendorDetails.vendorId) &&
                (!selectedDate || item.invoiceDate === selectedDate) &&
                (!searchInput || (item.invoiceNumber && item.invoiceNumber.includes(searchInput))) 
            );
    
            setFilteredInvoices(filteredData);
            setShowLoader(false);
        }
    }, [vendorDetails.vendorId, selectedDate, searchInput, invoiceData]);
    

    // Show notification if there is an error
    useEffect(() => {
        if (vendorsError || invoiceDataError) {
            setNotificationMsg({
                severity: "error",
                message: "Error loading data. Please try again.",
            });
            setShowNotification(true);
        }
    }, [vendorsError, invoiceDataError]);

    // Display loader while data is loading
    if (vendorsLoading || invoiceDataLoading) {
        return <Loader open={true} />;
    }

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Autocomplete
                        size="small"
                        disablePortal
                        id="vendor-select"
                        options={vendorOptions}
                        sx={{ width: 200, backgroundColor: "#EAECF0FF", marginRight: 2 }}
                        onChange={handleVendorChange}
                        renderInput={(params) => <TextField {...params} label="Select Vendor" />}
                    />

                    <TextField
                        label="Select Date"
                        type="date"
                        size="small"
                        value={selectedDate}
                        onChange={handleDateChange}
                        sx={{ width: 200, backgroundColor: "#EAECF0FF", marginRight: 2 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        label="Search"
                        size="small"
                        type="text"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        sx={{ width: 200, backgroundColor: "#EAECF0FF", marginRight: 2 }}
                    />
                </Box>

                {/* <CButton
                    size="small"
                    style={{ backgroundColor: "#FFB91DFF", color: "white" }}
                    type="button"
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    text="Export as Excel"
                /> */}
            </Box>

            <Box sx={{ padding: 1 }}>
                <InvoiceTable data={filteredInvoices} vendorData={vendorDetails} />
            </Box>

            {showNotification && (
                <Notification
                    open={showNotification}
                    onClose={() => setShowNotification(false)}
                    severity={notificationMsg.severity}
                    message={notificationMsg.message}
                />
            )}
        </Box>
    );
};
